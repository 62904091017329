import { Box, Typography } from "@mui/material";
import appConfig from "appConfig";

export default function TACContent() {
  return (
    <Box>
      <Typography fontSize={24} fontWeight={700} color={"#495057"} mb={2}>
        Terms and conditions
      </Typography>
      
      <Box mb={2}>
      <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Date: 2024.03.15
        </Typography>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Terms of Use
        </Typography>
        <Typography fontSize={14}>
          These Terms of Use govern your use of the web site located at&nbsp;
          {process.env.REACT_APP_BASE_URL} &nbsp;(the "Site"). These Terms of Use
          apply to all users of the site, whether visitors who merely browse the
          Site or users who register as patients or caregivers to receive
          services from the Site.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Description of the Site
        </Typography>
        <Typography fontSize={14}>
          The site provides an on-line healthcare service that allows users to
          connect to different healthcare providers, gather the clinical
          history, share the symptoms or the list of common minor conditions
          supported within our service menu to a licensed medical doctor who
          shall provide a diagnosis and treatment plan. THE SITE IS NOT INTENDED
          FOR USE IN A MEDICAL EMERGENCY OR IN CASE OF AN URGENT HEALTHCARE
          NEED. If appropriate, you may be referred to an alternative setting to
          receive care. You are charged a fee by&nbsp;
          {appConfig.name}&nbsp;only if a diagnosis and treatment plan are
          provided to you.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Use of the Site
        </Typography>
        <Typography fontSize={14}>
          If you register to receive services from&nbsp;{appConfig.name}
          &nbsp;as a patient, we will rely on you to provide accurate and
          complete information throughout both the clinical interview and the
          registration process, in order to provide appropriate care to you. You
          may access the Site's services for yourself, or for your minor
          children as permitted by the rules of the site. In addition, you must
          provide a phone number that can be used by us to contact you with any
          questions after completing the interview in order to provide a
          diagnosis and treatment plan via the site. You also must notify us as
          soon as you become aware of any critical change to your information
          that could impact an active treatment plan provided through the Site.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Changes in Terms of Use
        </Typography>
        <Typography fontSize={14}>
          We reserve the right to revise and update these Terms of Use. Any such
          revisions will be effective on the date of posting to the Site. Your
          use of the Site following any such revisions will be deemed your
          acceptance of such revisions. You should periodically visit the Site
          to review the current terms to which you are bound.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Cookies and Web Beacons
        </Typography>
        <Typography fontSize={14} mb={0.75}>
          To learn what areas of our Site are most popular, we collect
          anonymous, non-personal data about the pages you go to through the use
          of small files called "cookies" and "web beacons" or "pixels tags,"
          which may be sent by your web browser to us and stored on your
          computer's hard drive. For example, we may use cookies to enable you
          to use certain website features, store your preferences or recognize
          you when you return to our Site. We may use web beacons (also called
          pixel tags) on email communication with you (in the form of small
          graphic images) or on our Site to analyze your online behavior and
          measure the effectiveness of our Site and the services we provide to
          you. Each cookie is automatically deleted after a period of time or
          when you close your web browser. We use both cookies and web beacons
          to help us enhance your experience on our Site, improve navigation and
          site design and learn more about your preferences.
        </Typography>
        <Typography fontSize={14}>
          At this time, we do not honor "do not track" signals from a web site
          browser. You are able to limit the computer data we collect from you
          by setting your web browser to not accept cookies. If you limit the
          data we collect, some features on our Site may not work properly for
          you and we may not be able to provide certain services to you through
          our Site. We utilize third party applications to collect and manage
          usage data (for example, how many visitors we have to the Site). Third
          party application vendors may have access to the usage data that we
          collect.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Login Security
        </Typography>
        <Typography fontSize={14}>
          As part of the registration process for patients or caregivers, the
          Site requires that you create a unique personal login ID and password.
          You are solely responsible for maintaining the safety and security of
          your login ID and password. You should change your password if you
          believe that confidentiality has been compromised.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Copyright
        </Typography>
        <Typography fontSize={14}>
          The content of the Site, including, without limitation, text,
          graphics, images and other material, including software developed and
          licensed by us, which permits the access and use of the Site
          (collectively the "Content"), is protected by intellectual property
          laws, including copyright laws. All right, title and interest in and
          to the Content remains vested in us and shall not pass to you or any
          other user.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Sharing Information with Third Party Sites
        </Typography>
        <Typography fontSize={14}>
          {appConfig.name}, utilizes several third party applications and
          vendors to provide services, including an electronic prescribing
          vendor and insurance billing company. As part of the service,&nbsp;
          {appConfig.name}&nbsp;may have access to your prescription history if
          provided to our e-prescribing vendor by your health plan.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Links to Other Sites
        </Typography>
        <Typography fontSize={14}>
          The Site and Content may contain links to web sites operated by third
          parties. These links are provided solely for convenience and reference
          purposes only. The inclusion of any such link does not imply that we
          endorse the content of any web site to which the Site provides a link,
          nor are we liable for your reliance on or use of any information or
          materials contained in them.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          User Feedback
        </Typography>
        <Typography fontSize={14}>
          You may be asked to provide feedback on your&nbsp;{appConfig.name}
          &nbsp;experience. This feedback is always optional and your choice.
          You agree to not provide any personally identifiable information such
          as name or insurance ID. This feedback may be posted on&nbsp;
          {appConfig.name}.com&nbsp; and shared with the public after confirming that
          it does not contain any identifying information. Your feedback may be
          hosted and stored at a third party site.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Limitation of Liability
        </Typography>
        <Typography fontSize={14}>
          In no event shall we be liable to anyone for any delays, inaccuracies,
          errors or omissions with respect to the Site or the transmission or
          delivery of all or any part of the services, for any damage arising
          thereof, or any results obtained from the use of the Site.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography fontSize={18} mb={1} fontWeight={700} color={"#495057"}>
          Governing Law and Forum for Disputes
        </Typography>
        <Typography fontSize={14}>
          The existence of the Site is not intended and should not be construed
          to mean that we are conducting business in all states of the United
          States of America, or that we consent to submit to the personal
          jurisdiction of the state or federal courts in any state in the United
          States, except the State of Minnesota. Any claim or action arising
          directly or indirectly from the use of the Site be brought in
          Minnesota state court in Hennepin County, Minnesota or in the United
          States District Court for the District of Minnesota and shall be
          construed in all respects under the laws of the State of Minnesota,
          exclusive of its choice of law or conflict of laws provisions.
        </Typography>
      </Box>
    </Box>
  );
}
