import { Dialog, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import appConfig from "appConfig";

export default function PrivacyPolicyPopup({ show, close }) {
  return (
    <Dialog open={show} onClose={close} maxWidth="md">
      <IconButton
        onClick={close}
        sx={{
          position: "absolute",
          top: ".5rem",
          right: ".5rem",
        }}
      >
        <Close
          sx={{
            borderRadius: "99rem",
            p: ".2rem",
            bgcolor: "#f1f1f1",
          }}
        />
      </IconButton>
      <Box
        sx={{
          mx: "auto",
          padding: "0 23px 50px 23px",
          marginTop: "50px",
          py: 2,
          px: 6,
          overflowY: "auto",
        }}
      >
        <h3 className="terms-header">Privacy Policy</h3>
        <h4 className="terms-subheader">Date: 2024.03.15</h4>
        <h5 className="terms-con-header">Terms of Use</h5>
        <p className="term-text">
          At Ocean Deep Technologies, LLC , safeguarding your privacy and protecting 
          your personal health information is our top priority. We are committed to 
          maintaining the privacy and security of your personal information. 
          This Privacy Policy describes how Ocean Deep Technologies, LLC 
          ("ODT," "&nbsp;{appConfig.name}&nbsp;" "we" or "us") may collect, use and 
          share your personal information when you visit the Ocean Deep Technologies 
          application website at &nbsp;{process.env.REACT_APP_BASE_URL}&nbsp; or use the 
          Ocean Deep Technologies mobile application to access personal health report 
          (collectively, the "Services"). This Privacy Policy applies only to our online 
          and mobile registered users with regards to the information that they shared 
          and/or collect in the app. This policy is not applicable to any information 
          collected offline or via medium other than this application website or 
          mobile app.
        </p>
        <h5 className="terms-con-header">Acceptance of Privacy Policy</h5>
        <p className="term-text">
          By using our Services, you agree to the terms of this Privacy Policy. If you 
          do not agree to the terms of this Privacy Policy, please do not use the 
          Services. Your continued use of the Services after we make changes to this 
          Privacy Policy will mean that you agree to those changes.
          We collect your personal information through our application website when 
          you choose to provide that information, such as when you:
          <ul>
            <li>submit information through the "Profile" page of our website and mobile app.</li>
            <li>register on our website and mobile app.</li>
            <li>respond to our communications (for example, when you respond to an email from us).</li>
          </ul>
          The personal information that we collect can include your:
          <ul>
            <li>name</li>
            <li>email address</li>
            <li>phone number</li>
            <li>mailing address</li>
          </ul>
          When you sign up or register on our website, we may ask you for your name, 
          email address, mailing address, phone number or other information.
          provided to you.
        </p>
        <h5 className="terms-con-header">Through our mobile application</h5>
        <p className="term-text">
          Our mobile application collects your personal information when you create a new account:
          <ul>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Date of Birth</li>
            <li>Address</li>
            <li>Phone Number</li>
            <li>Email Address</li>
          </ul>
          In addition, we collect and keep your medical information through the 
          Electronic Health Record and sync your medical record information from your healthcare 
          provider or another third-party source using our Connect Healthcare Provider feature.
        </p>
        <h5 className="terms-con-header">Use & Sharing of Personal Information</h5>
        <p className="term-text">
          We will not sell, rent, license, or trade your personal information with third 
          parties for their own direct marketing use. Unless you give us your permission, 
          we will not share your personal information other than as stated in this Privacy 
          Policy.
          We may use the information we collect from you when you sign up, register, 
          respond to a survey or marketing communication, or use certain other features 
          of the Services in the following ways:
          <ul>
            <li>To personalize your experience on the website and to allow us to 
            deliver content and product offerings that interest you.</li>
            <li>To allow us to better respond to your customer service requests.</li>
            <li>To quickly process your requested transactions.</li>
          </ul>
        </p>
        <h5 className="terms-con-header">You Control How Your Medical Information Is Shared</h5>
        <p className="term-text">
          When you use Ocean Deep Technologies XpertView app and schedule an appointment 
          with Practitioner, you can decide what medical record you want to share. 
          Based on the medical condition, Practitioner may request additional medical 
          reports that you need to share. This is in order to provide you the best 
          consulting experience. You can however, revoke access to your report prior to 
          the appointment.
        </p>
        <h5 className="terms-con-header">Registration Information, Portal Information and Portal Credentials</h5>
        <p className="term-text">
          Our Services require registration, which involves giving us your email address, 
          a password and a username (the "Registration Information"). In order to fully 
          benefit from our Services, you also must provide your third-party health portal 
          credentials ("Portal Credentials") to allow us to access your health data at those 
          other healthcare providers' organizations ("Portal Information") for your use.
        </p>
        <p className="term-text">
          Access to your Registration Information, Portal Credentials, Portal Information 
          and any other personal information you provide is protected by our specific 
          internal procedures and safeguards restricting access to that information, 
          so that we can ensure it is only used to operate, develop or improve the Services.
        </p>        
        <h5 className="terms-con-header">Information Shared with Third Parties Assisting in Our Operations</h5>
        <p className="term-text">
          We may share your personal information under confidentiality agreements with 
          other companies that provide products and services on our behalf, such as those:
          <ul>
            <li>providing cloud hosting services,</li>
            <li>scheduling an appointment,</li>
            <li>processing payments,</li>
            <li>operating our website, mobile application,</li>
            <li>providing support and maintenance services for the Services, as well as
              legal, regulatory, audit and other professional advisors.</li>
          </ul>
          These companies described above may use your personal information to assist us 
          in our operations. However, these companies do not have any independent right 
          to share your information.
        </p>
        <h4>Information Shared Under Special Circumstances</h4>
        <p className="term-text">
          We may provide information about you:
          <ul>
            <li>to respond to subpoenas, court orders, legal processes or governmental regulations,</li>
            <li>to establish or use our legal rights or defend against legal claims,</li>
            <li>to investigate, prevent or take action regarding illegal activities, suspected fraud, or situations involving potential threats to the physical safety of any person, or</li>
            <li>as otherwise required by law.</li>
          </ul>
        </p>
        <h5 className="terms-con-header">
          Business Transfers
        </h5>
        <p className="term-text">
          We may share your personal information with other businesses in connection 
          with the sale, assignment, merger or other transfer of all or a portion of our 
          business to those businesses. We will require those businesses to honor the 
          rules of this privacy policy.
        </p>
        <h5 className="terms-con-header">Dormant, Closed or Terminated Accounts</h5>
        <p className="term-text">
          If your account is unused for an extended period, we may suspend or 
          “lockdown” your account in order to better safeguard your personal 
          information, and we will notify you when that occurs. If your account is 
          suspended because it is unused, we will retain the personal information in 
          your account for two years.  At the end of that two-year period, we will
          delete the personal information in your account in order to better protect 
          your privacy. Prior to deletion of your personal information, we will 
          attempt to notify you.
        </p>
        <p className="term-text">
          If your account is closed by you or terminated by us in accordance with 
          our Terms of Service, we will promptly delete the personal information 
          in your account, with the exception of a disclosure log that records 
          how you shared the information in your account with third parties.  
          The disclosure log will be maintained only for so long as is necessary for 
          our business purposes and will be deleted as soon as practicable, in 
          accordance with our record retention policies. You may close your account 
          and request deletion of your data at any time by contacting us 
          at privacy@oceandeeptechnoligies.com          
        </p>
        <h5 className="terms-con-header">Security</h5>
        <p className="term-text">
          We understand that storing our data in a secure manner is important. We store 
          your personal information and other data using industry standard physical, 
          technical and administrative safeguards to secure data against foreseeable 
          risks, such as unauthorized use, access, disclosure, destruction or 
          modification. Please note, however, that while we have tried to create a 
          secure and reliable website for users, the confidentiality of any 
          communication or material transmitted to/from our website or via email 
          cannot be guaranteed.
        </p>
        <h5 className="terms-con-header">Response to "Do Not Track" Signals</h5>
        <p className="term-text">
          Some Internet browsers include the ability to transmit "Do Not Track" 
          signals. Since uniform standards for "Do Not Track" signals have not yet 
          been adopted, we do not process or respond to "Do Not Track" signals.
        </p>
        <h5 className="terms-con-header">
          Children's Privacy Policy
        </h5>
        <h4 className="terms-con-subheader">
          Under Age 13
        </h4>        
        <p className="term-text">
          We understand the importance of protecting children's privacy in the 
          interactive online world. Our application is not designed for, or 
          intentionally targeted at, children under 13 years of age. It is not our 
          policy to intentionally collect or maintain information about anyone under 
          the age of 13. No one under the age of 13 should submit any personal 
          information to us or our website. If inadvertent collection occurs, we 
          will promptly take steps to delete that information.
        </p>
        <p className="term-text">
          It is important to note that our website and mobile app is operated in the 
          United States. If you are located outside of the United States, please be 
          aware that any personal information you provide to us will be transferred 
          to the United States. By using our website and/or providing us with your 
          personal information, you agree to this transfer.          
        </p>
        <p className="term-text">
          You may also request deletion of your personal information from our databases 
          in order to close your account and prevent receipt of future communications. 
          When you choose to delete your account, all of your personal information 
          associated with the account is deleted immediately, and is no longer 
          accessible by you or Ocean Deep Technologies app &nbsp;
          {appConfig.name}.&nbsp;          
        </p>
        <p className="term-text">
          You may submit requests to change or delete your personal information using 
          either of the following options:
          <ul>
            <li>
            You can send your request via email to privacy@oceandeeptechnologies.com.
            </li>
          </ul>          
        </p>
        <h5 className="terms-con-header">
          Policy Updates
        </h5>
        <p className="term-text">
          This Privacy Policy may be revised from time to time as we add new features 
          and services, as laws change, and as industry privacy and security best 
          practices evolve. We display an effective date at the top of this Privacy 
          Policy so that it will be easier for you to know when there has been a change. 
          If we make any change to this Privacy Policy regarding use or sharing of 
          personal information, we will provide advance notice on our website, and 
          will notify you of the most recent changes to the policy, highlighting and 
          explaining those changes. If you don't approve of a change to this Privacy 
          Policy, you will have the opportunity to Cancel the Services. If you are a 
          user of our mobile application, this Privacy Policy will also be available 
          in the app store from which you downloaded the app. Small changes or changes 
          that do not significantly affect individual privacy interests may be made at 
          any time and without prior notice.          
        </p>
      </Box>
    </Dialog>
  );
}
